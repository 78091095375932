
import NavBar from "../components/NavBar"
import Alert from "../components/Alert"
import { useEffect, useState } from "react";
import ReactTypingEffect from 'react-typing-effect';
import { Link, useNavigate } from "react-router-dom";

// Import the components 
import FloatingButton from "../components/FloatingButton";

// Import the images
import ocmoney from "../assets/img/images/oc-money-profits.svg";
import highFive from "../assets/img/images/oc-hi-five.png";
import answers from "../assets/img/images/oc-looking-for-answers.svg"
import homeKeys from "../assets/img/images/homekeys.png"
import family from "../assets/img/images/family.png"
import client1 from "../assets/img/testimonial/client-1.png"
import star from "../assets/img/icons/star.png";

const Home = (props) => {
   
    const navigate = useNavigate();

    const [navClass, setNavClass] = useState("");
    const [minNavClass, setMinNavClass] = useState("");
    const [zipCode, setZipCode] =  useState("");
    
    const onChange = (e) => {
        setZipCode(e.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        navigate("/apply", {
            state: {
                zipCode
            }
        })
    }

    const handleScroll = () => {
        if ( window.pageYOffset >= 100 )  {
                setNavClass("scrolling-nav");
                setMinNavClass("mini-bar-scroll")
            } else { 
                setNavClass("")
                setMinNavClass("")

           }
     }
    useEffect(() => {
       
        window.addEventListener('scroll', handleScroll);
         return () => {
            window.removeEventListener('scroll', handleScroll)
          }
    }, [navClass, minNavClass])

  
    return (
        <>
         <FloatingButton />
         <Alert className={minNavClass}/>
         <NavBar className={`navbar-main ${navClass}`} />
        <main>
             <header className="page-header row bg-primary-soft h-100">
                        <div className="bg-hero bg-hero-1"></div>
                        <div className="bg-hero  bg-hero-3"></div>

                        <div className="col-12 my-auto page-header-content">
                            <div className="bg-hero bg-hero-2"></div>
                            <div className="mt-5">
                                <div className="row">
                                    <div className="col-11 m-auto col-md-12 page-header-section" >
                                        <h1 className="page-header-title justify-center	">Mortage made <br/> easy with 
                                        <ReactTypingEffect
                                            text={["Tech"]} 
                                            typingDelay={500}
                                            cursorRenderer={cursor => <span className="mx-2" style={{"color": "#f1ba80"}}> {cursor}</span>}
                                            displayTextRenderer={(text, i) => {
                                                return <span className="mx-2" style={{"color": "#f1ba80"}}> {text}</span>
                                            }}/>
                                        </h1>
                                        <span className="page-header-text">
                                        Your home is more than a place to live. It’s an investment in a better future. We’ll help you unlock a better home and keep it working for you.


                                        </span>
                                        
                                        <form onSubmit={onSubmit} className="call-to-action mt-3">
                                            <div className="call-to-action-content mt-2" >
                                                <input className="call-to-action-input" placeholder="Enter your zip code or address" type="text" onChange={onChange} value={zipCode}/>
                                                <button className="btn btn-primary" >Get rates</button>
                                            </div>
                                        </form>
                                        <div className="row mini-feature">
                                           <div className="col-12 col-md-2">
                                                <i className="bi bi-chat-left-dots-fill text-primary"></i>
                                                <div>
                                                    <span className="text-primary">24 hours</span>
                                                    <p className="font-weight-normal">Support</p>
                                                </div>
                                           </div>
                                           <div className="col-12 col-md-3">

                                            <i className="bi bi-lightning-charge-fill text-primary"></i>
                                            <div>
                                                <span className="text-primary">Fastest</span>
                                                <p className="font-weight-normal">Approval</p>
                                            </div>
                                       </div>
                                        </div>
                                </div>
                               </div>
                            </div>
                        </div>
                       
                    </header>
                    <section className="bg-white py-5">
                        <div className="container">

                            <div className="row justify-content-center mb-5">
                                <div className="col-12 text-left text-md-center">
                                    <h1 className="page-header-title">Your pathway to success</h1>
                                    <p className="page-header-text d-none d-md-block">Choose your English proficiency exam to fulfill your dream of Overseas Education <br/> and 
Immigration purpose with the Mock Test, Online Coaching, and Offline Coaching.

 
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="bg-white ">
                            <div className="row container whyus" >

                                   <div className="card col-12 col-md-4 py-4 px-2 bg-primary-soft mx-auto mx-md-1 mb-3">
                                   <div className="card-header">
                                    <img src={ocmoney} width="200" />
                                   </div>
                                   <div className="card-body pt-0 ">
                                    <h3 className="font-bold">Value for money</h3>

                                                                           <p>
                                                                           We get you the best mortgage product for your needs
                                                                                                                   </p>
                                   </div>
                                </div>
                             

                                <div className="card col-12 col-md-4 py-4 px-2 bg-secondary-soft mx-auto mx-md-1 mb-3">
                                    <div className="card-header">
                                        <img src={highFive} width="160" />

                                   </div>
                                   <div className="card-body pt-0">
                                    <h3 className="font-bold">Fastest Approval</h3>

                                                                           <p>
                                                                           Get approval as fast as in hours as oppose to days or weeks. 
                                                                                                                   </p>
                                   </div>
                                </div>
                             

                                <div className="card col-12 col-md-4 py-4 px-2 bg-success-soft mx-auto mx-md-1 mb-3">
                                    <div className="card-header">
                                        <img src={answers} width="200" />

                                   </div>
                                   <div className="card-body pt-0">
                                    <h3 className="font-bold">24/7 support</h3>

                                                                           <p>
                                                                          We are avaialble 7 days a week for yours questions and assitance.
                                                                                                                   </p>
                                   </div>
                                </div>

                               


                              

                                

                                
                               
                            </div>
                        </div>
                        
                    </section>

                    <section className="py-md-3 features">
                        <div className="container">

                       

                            <div className="row">
                                <div className="col-12 col-md-6 order-md-2 text-center m-auto px-0">
                                    <img src={homeKeys} />
                                    
                                </div>
                                <div className="col-12 col-md-6 order-md-1 text-left my-5 mt-md-10">
                                    <h1 className="page-header-title">First time <br/>home buyer </h1>
                                   <p> Are you purchasing your first home? We know the homeowning process is tiring, and you can miss out on the best deals out there. Well, not anymore with our AI platform and mortgage agents you can get your hands on the best mortgage product.                                        
                                    </p>            
                                    <Link to="/apply">
                                        <button className="btn btn-outline-primary px-5">Get started</button>
                                    </Link>
                                </div>
                              
                            </div>


                        </div>
                    </section>
                    

                    <section className="py-10">
                        <div className="container">

                       

                            <div className="row">
                                <div className="col-12 col-md-5 order-md-1 text-center">
                                    <img src={family} className="family-img" />
                                    
                                </div>
                                <div className="col-12 col-md-6  order-md-2 text-left mt-5 mt-md-5">
                                    <h1 className="page-header-title">Current <br/> Homeowners </h1>
                                    <p className="page-header-text " style={{"fontSize": "22px !important", "fontWeight": "normal"}}>Are you looking to refinance your home or renew your current mortgage with better rates?

                                        
                                    </p> 
                                    <Link to="/apply">
                                        <button className="btn btn-outline-primary px-5">Get started</button>
                                    </Link>
                                </div>
                              
                            </div>


                        </div>
                    </section>

                    <section className="py-5">
                        <h1 className="page-header-title text-center mb-4">What our clients say about us?</h1>
                        <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
                          <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                          </ol>
                          <div className="carousel-inner row mx-auto">
                            <div className="carousel-item active text-center col-11 col-md-7 mx-auto">
                              <img className="d-block mb-2" width="100" src={client1} alt="First slide"/>
                              <h3 className="mb-1">Manjot Singh</h3>
                              <span className="page-header-text">Brampton residence and new home owner</span>
                              <div className="rating">
                                
                                    <img src={star}/>
                                    <img src={star}/>
                                    <img src={star}/>
                                    <img src={star}/>
                                    <img src={star}/>
                              

                              </div>
                              <p className="font-italic mt-4">“I never knew I had so many options at my disposal for mortgage financing. Amit helped us mortgage plan well under our need.”</p>
                            </div>
                            <div className="carousel-item">
                              <img className="d-block w-100" src="..." alt="Second slide" />
                            </div>
                            <div className="carousel-item">
                              <img className="d-block w-100" src="..." alt="Third slide"/>
                            </div>
                          </div>
                          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </div>
                    </section>

                </main>
            </>
    )
}

export default Home;
