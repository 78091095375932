import './App.css';
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Rates from "./pages/Rates";
import Form from "./pages/Form";
import About from "./pages/About"
import Contact from "./pages/Contact"
import Connect from "./pages/Connect"


import Footer from "./components/Footer"



function App() {



  return (
   <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rates" element={<Rates />} />
          <Route path="/apply" element={<Form />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/connect" element={<Connect />} />
        </Routes>
      </Router>
      <Footer />

    </>
  );
}

export default App;
