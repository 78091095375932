import { Widget } from '@typeform/embed-react'
import NavBar from "../components/NavBar"
import { useLocation } from "react-router-dom"

const Form = (props) => {
    const location = useLocation();
    const zipcode = location.state && location.state.zipCode || null;

    return (
        <> 
            <NavBar />
            <Widget 
                id="Y7gbKuML" 
                hidden={zipcode && {zipcode}}
                className="my-form my-10" 
                inlineOnMobile={true}
                style={{ width: '100%%' }}
             />
        </>
    )
}
export default Form;