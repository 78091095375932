import whatsappImg from "../assets/img/icons/whatsapp.png";

const Alert = (props) => {
    return (
             <div className={`mini-bar ${props.className}`}>
                    <div className="row">
                   <div className="col-12 col-md-11 m-auto">
                   <a href="tel: +16475700420"> <img src={whatsappImg} width="36" className="mx-1" />
                                  <span>Questions?</span><span className="callus"> Click here to call us</span>
                                  <p className="ml-2 d-inline"><u>Call +1 647-570-0420</u></p></a>
                                </div>
                            </div>
                    </div>
    )
}

export default Alert;
