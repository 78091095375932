import whatsapp from "../assets/img/icons/whatsapp.png"
import phone from "../assets/img/icons/phone.png"
import email from "../assets/img/icons/email.png"

import icon1 from "../assets/img/icons/icons-1.png"
import icon2 from "../assets/img/icons/icons-2.png"
import icon3 from "../assets/img/icons/icons-3.png"
import icon4 from "../assets/img/icons/icons-4.png"
import icon5 from "../assets/img/icons/icons-5.png"


const Footer = () => {

    return (

        <footer className="px-4 pt-5 px-md-10" style={{background: "#f7f9fc"}}>
        <div className="row">
        <ul className="col-md-4 col-12 mb-5">
                <li>
                    <h1 style={{color: "#000", fontWeight: "700", marginBottom: "30px"}}>AMIT</h1>
                </li>

                <li>
                We focus on making mortages simple and accessable for all Canadians. With help of cutting edge technolgy and multiple locations across ontariot.
                </li>
               
            </ul>
         
            <ul className="col-md-4 col-12 mb-5">
                <li className="my-4">
                    <p className="footer-label">Whatsapp</p>
                   <h1>+1 647-570-0420</h1>
                </li>
                <li className="my-4">
                    <p  className="footer-label">Phone</p>
                    <h1>+1 647-570-0420</h1>
                </li>
                <li className="my-4">
                    <p  className="footer-label">Email</p>
                     <h1>amitgagneja@live.com</h1>
                </li>
            </ul>

            <ul className="col-md-4 mb-5">
                <li>
                    <p className="footer-label" >Address</p>
                   <h1>2155 B Steeles Ave E unit 32, <br/>Brampton, ON L6T 5A1</h1>
                </li>
                <p  className="footer-label" style={{marginTop: "40px"}}>Social Media</p>
                
                <a href="https://www.facebook.com/amit.mortgage1?mibextid=LQQJ4d"><li className="float-left mr-1"><img src={icon2}  width="36"/></li></a>
                <a href="tel:+16475700420"><li className="float-left mr-1"><img src={icon3}  width="36"/></li></a>
                <a href="https://instagram.com/mortgage.by.amit?igshid=NTc4MTIwNjQ2YQ=="><li className="float-left"><img src={icon5}  width="36"/></li></a>
	               
            </ul>
        </div>
        <div className="row py-4 text-center">
            <p className="d-block w-100">2022. All rights to amit Technologies</p>
        
        </div>
    </footer>
    )
}

export default Footer
