import NavBar from "../components/NavBar"
import FloatingButton from "../components/FloatingButton"

import { Link } from "react-router-dom";

import amit from "../assets/img/images/amit.png"
import ocmoney from "../assets/img/images/oc-money-profits.svg";

const About = () => {
    return (
        <>  
            <FloatingButton />
            <NavBar />
            <section className="bg-secondary-light hero text-center">
                    <div className="row justify-content-center">
                            <h1 className="col-11 col-md-11 page-header-title text-primary text-left">Let's <span className="text-secondary">talk</span></h1>
                    </div>
                    <div className="row  justify-content-center">
                    <h1 className="col-11 col-md-11 page-header-section font-weight-normal text-left" style={{lineHeight: "34px"}}>Got a question? Want to learn more about us? Get in touch..</h1>
                    </div>

            </section>
           <section>
           <div className="row container my-5 mx-auto justify-content-center" >

            <div className="card bg-transparent col-12 col-md-3 py-4 px-2 mx-auto mx-md-3 mb-3">
                <div className="card-header p-0 my-0">
                <i class="bi bi-telephone-plus" style={{fontSize: "50px"}}></i>

                </div>
                <div className="card-body py-0 my-0 text-center">
                <h3 className="font-bold text-center text-primary">Call us</h3>

                    <p> We are available 24/7</p>
                    <hr/>
                    <a href="tel: +16475700420"><p className="mb-2"><u>+1 647-570-0420</u></p></a>
                </div>
        </div>

        <div className="card bg-transparent col-12 col-md-3 py-4 px-2 mx-auto mx-md-3 mb-3">
                <div className="card-header p-0 my-0">
                    <i class="bi bi-envelope-open-fill" style={{fontSize: "50px"}}></i>

                </div>
                <div className="card-body py-0 my-0 text-center">
                <h3 className="font-bold text-center text-primary">Send us a email</h3>

                    <p>We will answer within hours</p>
                    <hr/>
                    <a href="mailto: me@shiv.ca"><p className="mb-2"><u>amitgagneja@live.com</u></p></a>

                </div>
        </div>

        <div className="card bg-transparent col-12 col-md-3 py-4 px-2 mx-auto mx-md-3 mb-3">
                <div className="card-header p-0 my-0">
                    <i class="bi bi-building" style={{fontSize: "50px"}}></i>

                </div>
                <div className="card-body py-0 my-0 text-center">
                <h3 className="font-bold text-center text-primary">Our mailing address</h3>

                    <p>We will answer within hours</p>
                    <hr/>
                    <p className="mb-2"><u>2155 B Steeles Ave E unit 32, Brampton, ON L6T 5A1</u></p>

                </div>
        </div>
</div>
           </section>
                    
        </>
    )
}

export default About;
