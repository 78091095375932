import {Link} from "react-router-dom";

const FloatingButton = () => {
    return (
        <div className="floating-button">
            <Link to="/apply">
                <button className="btn btn-primary w-100 p-3 font-weight-bold">Apply for a mortgage</button>
            </Link>
        </div>
    )
}

export default FloatingButton;