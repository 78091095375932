import twitter from "../assets/img/images/twitter.png";
import intagram from "../assets/img/images/instagram.svg";
import imessages from "../assets/img/images/imessages.png";
import facebook from "../assets/img/images/facebook.png";
import iphone from "../assets/img/images/iphone.png";
import whatsapp from "../assets/img/images/whatsapp.png";
import amit from "../assets/img/images/amit.png";
import contactCard from "../assets/Amit_Gagneja.vcf";

const Connect = () => {
  return (
    <>
      <div className="row text-center">
        <div className="col-12 connect-card">
          <img className="profile-photo" src={amit} />
        </div>
        <div className="col-12 mt-10 title">
          <h2>
            <strong>Amit Gagneja</strong>
          </h2>
          <p>Mortgage Broker</p>
        </div>
      </div>
      <div className="container row social">
        <a href={contactCard} className="save-btn">
          <button className="btn btn-dark mb-4">Save Contact</button>
        </a>
        <ul className="row">
          <li className="col-4">
            <a href="https://mobile.twitter.com/amitgagneja1" taget="_blank">
              <img src={twitter} width="80" />
              <p>Twitter</p>
            </a>
          </li>
          <li className="col-4">
            <a
              href="https://instagram.com/mortgage.by.amit?igshid=YmMyMTA2M2Y="
              taget="_blank"
            >
              <img src={intagram} width="80" />
              <p>Instagram</p>
            </a>
          </li>
          <li className="col-4">
            <a href="sms:(+1)(6477846793)" taget="_blank">
              <img src={imessages} width="80" />
              <p>Message</p>
            </a>
          </li>
          <li className="col-4">
            <a href={contactCard} taget="_blank">
              <img src={iphone} width="80" />
              <p>Contact</p>
            </a>
          </li>
          <li className="col-4">
            <a
              href="https://www.facebook.com/amit.mortgage1?mibextid=LQQJ4d"
              taget="_blank"
            >
              <img src={facebook} width="80" />
              <p>Facebook</p>
            </a>
          </li>
          <li className="col-4">
            <a href="https://wa.me/16477846793" taget="_blank">
              <img src={whatsapp} width="80" />
              <p>Whatsapp</p>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Connect;
