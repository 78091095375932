import NavBar from "../components/NavBar"
import FloatingButton from "../components/FloatingButton"

import { Link } from "react-router-dom";

import amit from "../assets/img/images/amit.png"
const About = () => {
    return (
        <>  
            <FloatingButton />
            <NavBar />
            <section className="bg-white hero text-center">
                    <div className="row justify-content-center">
                            <h1 className="col-11 col-md-9 page-header-title text-primary text-left text-md-center">Our mission is to make <span className="text-secondary">mortgages</span> easy</h1>
                    </div>
                    <div className="row  justify-content-center">
                    <h1 className="col-11 col-md-8 page-header-section font-weight-light text-left text-md-center" style={{lineHeight: "34px"}}>We focus on making mortages simple and accessable for all Canadians. With help of cutting edge technolgy and multiple locations across ontario.</h1>
                    </div>
            </section>
            <sectoin className="py-md-3 bg-primary-soft">
                        <div className="container">
                           <div className="row">
                            <div className="col-12 col-md-6 order-md-2 text-center">
                                <img src={amit}  width="400" />
                            </div>
                            <div className="col-12 col-md-6 order-md-1 text-left my-5 mt-md-10">
                                    <h2 className="page-header-title m-0 p-0">Amit Gagneja</h2>
                                    <h4 className="mb-3 text-primary font-weight-noraml"> The founder </h4>
                                    <p className="font-weight-normal">I can find the perfect mortgage for you. , I have the expertise to get the Right Mortgage for your immediate and future needs. I work for you, not the banks, and provide unbiased guidance in your mortgage decision. We work with over 60 lenders (some offered exclusively through brokers) so you have the choice, convenience and great counsel that you deserve!                     
                                    <Link to="/apply"><button className="btn btn-outline-primary d-block mt-3">Schedule a meet</button></Link>
                                </p>            
                            </div>
                          
                            </div>
                        </div>
                    </sectoin>

                    <section className="py-md-3 bg-primary-soft">
                        <div className="container">

                       

                            <div className="row justify-content-center">
                            
                                <div className="col-12 col-md-8 my-5 mt-md-10">
                                    <h1 className="page-header-title">Why choose us</h1>
                                    <p className="page-header-text">Even to this day, most Canadians are using big banks for their mortgages. Despite the face mortgage is the single most biggest leverage one would use in their entire life. Yet, research has found that Canadians opt for famous option and miss out on discounts and benefits offered by lesser know and brokers. We are here to bridge the gap by providing Canadians a transparent and easy to tool platform to get best out of your mortgage product.

                                    </p>            
                                    <Link to="/apply"><button className="btn btn-outline-primary px-5">Get started</button></Link> 
                                </div>
                              
                            </div>


                        </div>
                    </section>
        </>
    )
}

export default About;