import { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../components/NavBar"
import { Link } from "react-router-dom";
import { useForm } from '@formspree/react';

const Rates = (props) => {
 

    const [formData, setFormData] = useState({ 
        amount: "$300,000",
        years: "25 years",
        frequency: "Monthly",
        interest: "4.94%",
        downPayment: "$0"
    });
    
    const [mortgageData, setMortgageData] = useState({
        total: 0
    })

    // Send the request to fetch data from API 
    const API_CALL = async  () => {

        const data = {
            amount:  Number(formData.amount.replace(/\D/g,'')),
            years: Number(formData.years.replace(/\D/g,'')),
            interest:  Number(formData.interest.replace(/[^0-9$.,]/g, '')),
            downPayment: Number(formData.downPayment.replace(/\D/g,''))
        }
        
        const response = await axios.get("https://api.api-ninjas.com/v1/mortgagecalculator", {
            params: {
                loan_amount:  data.amount - data.downPayment,
                interest_rate: data.interest,
                duration_years: data.years,
              },
              headers: { 'X-Api-Key': '0V7pdDgUoW5JA01GSh4UMioBdaShO5kHpgOkmnVW' }
        })

        

        if ( response.data ) {
            
            setMortgageData({
                ...response.data.monthly_payment,
                "Monthly": response.data.monthly_payment.total,
                "Semi-Monthly": Math.round(response.data.monthly_payment.total / 2),
                "Bi-Weekly": Math.round(response.data.monthly_payment.total / 2),
                "Weekly": Math.round(response.data.monthly_payment.total / 4),
            });
            setFormData({
                ...formData,
                amount: "$" + data.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                years: data.years + " years",
                interest: data.interest.toFixed(2) + "%",
                downPayment: "$" + data.downPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            })
        }
        
    }

    // Send the inital API request with inital state data
    useEffect(() => {
        API_CALL();
    }, [])


    const onChange = async (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value  
        })
    }
    const [state, handleSubmit] = useForm("myyvgglb");

    const onSubmit =async  (e) => {
        e.preventDefault();
         // Call the api 
         handleSubmit(e);
         await API_CALL();
    }



    return (
        <>      
                <NavBar />
                 <section className="row mt-10 mb-md-5 Calculator" >
                            <div className="col-11 m-auto">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="col-12 mb-4">
                                            <h1 style={{"font-size": "40px"}}>Mortage</h1>
                                            <h1 style={{"font-size": "40px"}} className="text-primary font-weight-bold">Rate Calculator.</h1>
                                        </div>
                                        <form onSubmit={onSubmit} className="col-12">
                                            <div className="input-group col-6">
                                                <label className=" d-block w-100" >Mortage amount</label>
                                                <input 
                                                    className="form-control" 
                                                    value={`${formData.amount}`}
                                                    name="amount"
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="input-group col-6">
                                                <label for="text"  className=" d-block w-100">Mortgage length                                    </label>
                                                <input 
                                                    className="form-control" 
                                                    value={`${formData.years}`}
                                                    name="years"
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="input-group col-12">
                                                <label for="text"  className=" d-block w-100">Where to send result?                                   </label>
                                                <input 
                                                    className="form-control" 
                                                    value={formData.email}
                                                    name="email"
                                                    onChange={onChange}
                                                    required
                                                    placeholder="Your email address"
                                                />
                                            </div>
                                            <div className="input-group col-12">
                                                <label for="text"  className=" d-block w-100">Payment frequency                                  </label>
                                                <select className="form-control" 
                                                name="frequency"
                                                value={formData.frequency} onChange={onChange}>
                                                    <option selected value="Monthly">Monthly</option>
                                                    <option value="Semi-Monthly">Semi-Monthly</option>
                                                    <option value="Bi-Weekly">Bi-Weekly</option>
                                                    <option value="Weekly">Weeekly</option>
                                                </select>
                                            </div>
                                            <div className="input-group col-6">
                                                <label for="text"  className=" d-block w-100">Intereset rate                                    </label>
                                                <input 
                                                    className="form-control" 
                                                    value={`${formData.interest}`}
                                                    name="interest"
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="input-group col-6">
                                                <label for="text"  className=" d-block w-100">Down payment                                    </label>
                                                <input 
                                                    className="form-control" 
                                                    value={`${formData.downPayment}`}
                                                    name="downPayment"
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <div className="input-group col-12">
                                                <button className="btn btn-primary">Calculate</button>
                                            </div>
                                           
                                        </form>
                                    </div>
                                    <div className="row footer-result">
                                        <div className="col-5"> 
                                                <span className="font-weight-normal">Payments</span>
                                                <h5 className="mt-2 font-weight-bold">${mortgageData[formData.frequency]} {formData.frequency}</h5>
                                            </div>
                                            <div className="col-5"> 
                                                <span className="font-weight-normal">Mortgage amount</span>
                                                <h5 className="mt-2 font-weight-bold">{formData.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                                        </div>
                                        <div className="col-12"><hr/></div>
                                        <div className="col-12"><Link to="/apply"><button className="m-auto my-3 p-3 btn btn-primary w-100">Apply for a mortgage</button></Link></div>

                                    </div>

                                    <div className="card col-6 h-100">
                                        <div className="card-header text-left justify-content-left m-1">
                                            HERE'S YOUR MORTGAGE SCENARIO
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-4"> 
                                                <span className="font-weight-normal">Payments</span>
                                                <h1 className="mt-2 font-weight-bold">${mortgageData[formData.frequency]} {formData.frequency}</h1>
                                            </div>
                                            <div className="mb-4"> 
                                                <span className="font-weight-normal"> Mortgage amount</span>
                                                <h1 className="mt-2 font-weight-bold">{formData.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h1>
                                            </div>
                                           <button className="btn btn-outline-primary mb-2">Download detailed report</button>
                                        </div>
                                        <div className="card-footer bg-primary-soft">
                                          <span className="font-weight-normal"> Looking for a mortgage? Book free appointment.</span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            
                    </section>

                   
                

                 
        </>
    )
}

export default Rates;