import { Link } from "react-router-dom";
import { useState } from "react";
import whatsappImg from "../assets/img/icons/whatsapp.png";

const NavBar = (props) => {

    const [collapse, setCollapse] = useState(false);

    const onClick = () => {
        setCollapse(!collapse)
    }

    return (
       
                    <nav className={`navbar navbar-marketing navbar-expand-lg navbar-light ${props.className}`}>
                        <div className="container">
                            <Link className="navbar-brand " to="/">AMIT</Link><button className="navbar-toggler" type="button" data-toggle="collapse" onClick={onClick} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                            </button>
                            <div className={`collapse navbar-collapse ${collapse ? "show" : ""}`} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><Link className="nav-link" to="/rates">Rates</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="/about-us">Company</Link></li>
                                    <li className="nav-item"><a className="nav-link" href="/contact-us">Contact Us</a></li>
                                </ul>
                                <div className="nav-buttons">
                                    <Link to="/apply">
                                        <a className="btn btn-primary px-vr-30 font-weight-bold" href="">Apply for a mortgage</a>
                                    </Link>
                                </div>
                            </div>
                           

                        </div>
                    </nav>

    )
}

export default NavBar;